import { apiClient } from 'helpers/APIClient';
import { ProjectStatus } from 'typings/registerForm';
import { apiUrls } from 'variables';

interface Values {
  name: string;
  email: string;
  company: string;
  participantCategory: string;
  projectCategory: string[];
  companyName: string;
  website: string;
  country: string;
  designTeam: string;
  projectName: string;
  client?: string;
  location?: string;
  description: string;
  image1: string;
  credits1: string;
  image2: string;
  credits2: string;
  image3: string;
  credits3: string;
  image4: string;
  credits4: string;
  image5: string;
  credits5: string;
  [key: string]: unknown;
  charged_amount?: number;
}

const formatValues = (values: Values, filterEmpty: boolean) => {
  const projects = Array.from(Array(5).keys())
    .map(key => key + 1)
    .reduce(
      (acc: unknown[], curr) =>
        values[`image${curr}`] === ''
          ? acc
          : [...acc, { image: values[`image${curr}`], credits: values[`credits${curr}`] || '' }],
      [],
    );

  const assignedValues = {
    fullName: values.name,
    email: values.email,
    company: values.company,
    companyName: values.companyName,
    country: values.country,
    participantCategory: values.participantCategory,
    awardCategory: values.projectCategory,
    website: values.website,
    projectName: values.projectName,
    client: values.client,
    location: values.location,
    description: values.description,
    design_team: values.designTeam,
  };

  const filteredValues = Object.fromEntries(
    Object.entries(assignedValues)
      .filter(([_key, value]) => {
        if (!filterEmpty) {
          return true;
        }
        return value;
      })
      .map(([key, value]) => {
        const newValue = value === '' ? null : value;
        return [key, newValue];
      }),
  );

  return {
    ...filteredValues,
    ...(values.charged_amount && { charged_amount: Math.ceil(values.charged_amount) }),
    images: projects,
    projects,
  };
};

export const saveEntry = (
  values: Values,
  status: ProjectStatus,
  projectId: string,
  filterEmpty = false,
): Promise<unknown> => {
  const body = {
    ...formatValues(values, filterEmpty),
    status,
    isDeleted: false,
    // TODO: refactor this to use api config
    project_edition: '2025',
  };

  const method = projectId ? apiClient.put : apiClient.post;
  const url = projectId ? `${apiUrls.updateEntry}${projectId}/` : apiUrls.submitEntry;

  return method(url, body).then(res => res.data);
};
