import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'variables';

import * as S from './homepage/styles';

import category1 from 'assets/images/01.png';
import category2 from 'assets/images/02.png';
import category3 from 'assets/images/03.png';
import category4 from 'assets/images/04.png';
import home from 'assets/images/home.png';

export const Homepage: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categoryBlocks = [
    { img: category1, text: '(1) architectural design' },
    { img: category2, text: '(2) product design' },
    { img: category3, text: '(3) universal design' },
    { img: category4, text: '(4) responsive design' },
  ];

  return (
    <S.Wrapper>
      <S.SectionHeader>Join Edition 2025</S.SectionHeader>
      <S.RegisterButton onClick={() => history.push(routes.awardsRegister)}>
        Add entry to Awards 2025
      </S.RegisterButton>
      {/* <S.Paragraph>
        The awards are a multidisciplinary call for ideas and implementations in the categories of:
      </S.Paragraph>
      {categoryBlocks.map(block => (
        <S.CategoryBlock key={block.text}>
          <img src={block.img} />
          <span>{block.text}</span>
        </S.CategoryBlock>
      ))} */}

      {/* <S.SectionHeader>Schedule</S.SectionHeader>
      <S.Paragraph>
        UTC time zone for all dates. Please follow the official website of the awards for possible
        changes in the schedule.
      </S.Paragraph>

      <S.Schedule>
        <S.EarlySchedule>
          <span>
            Project
            <br />
            submission
            <br />
            deadline:
            <br />
            <br />
            <u>
              March 25
              <br /> 2025
            </u>
          </span>
        </S.EarlySchedule>
        <S.RegularSchedule>
          <span>
            Announcement
            <br />
            of winners:
            <br />
            <br />
            <u>
              June
              <br />
              2025
            </u>
          </span>
        </S.RegularSchedule>
        <S.LateSchedule>
          <span>
            Awards
            <br />
            ceremony:
            <br />
            <br />
            <u>
              September 19 & 20
              <br />
              2025
            </u>
          </span>
        </S.LateSchedule>
      </S.Schedule> */}

      <S.SectionHeader>Awards Ceremony</S.SectionHeader>
      <S.Paragraph>
        The awards ceremony will take place during the Architecture in Foyer 2025 worldwide conference at
        the Solarlux Campus in Germany (Melle, Lower Saxony, Germany) in September 2025.
        <br />
        <br />
        Please follow updates about the event on the official website:{' '}
        <S.Link href="https://architektur-im-foyer.com/en/" target="_blank" rel="noopener noreferrer">
          www.architektur-im-foyer.com/en/
        </S.Link>
      </S.Paragraph>

      <S.Image src={home} />
    </S.Wrapper>
  );
};
